import React from 'react'
import Image from 'next/image'
function ConvenienceOfOutsourcing({cosData}:any) {
   
  return (
    <section className="sections">
        <div className="container">
            <div className="sections_title">
                <h2 data-aos="fade" data-aos-delay="100">
                   {cosData?.title}
                </h2>
                <div data-aos="fade" data-aos-delay="200" dangerouslySetInnerHTML={{ __html: cosData?.description || '' }}>
                   
                </div>
            </div>
            <div className="row icon_sec">
                {
                    cosData.convenienceOfOutsourcingBox &&  cosData.convenienceOfOutsourcingBox.map((cosData:any, index:any)=>{
                        return    <div key={index} className="col-lg-6" data-aos="fade" data-aos-delay="100">
                        <div className="icon_box_hr">
                            <div className="fit_icon">
                                <Image src={cosData?.icon?.sourceUrl} width={80} height={80} alt={cosData?.icon?.altText} loading="lazy"/>
                            </div>
                            <div className="icon_text">
                                <h3>{cosData?.title}</h3>
                                <p>
                                    {cosData?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </div>
        <div className="animated_box"></div>
    </section>
  )
}

export default ConvenienceOfOutsourcing
